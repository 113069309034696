.chat::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../ui/img/fiscalbright_chat_bg1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 70%;
    z-index: 1;
}

.chat>* {
    position: relative;
    z-index: 2;
}