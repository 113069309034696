@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.calendar {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 32px 24px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px -8px rgba(26, 37, 255, 0.08);
    border: 1px solid #E4E4F7;
}

.header-label {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
}

.day-label {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: rgba(50, 50, 82, 1);
    display: flex;
    height: 32px;
    width: calc(100% / 7);
    justify-content: center;
    align-items: center;
}

.days {
    display: flex;
    justify-content: space-between;
}

.cell {
    font-family: 'Manrope';
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #45495F;
    display: flex;
    height: 40px;
    width: calc(100% / 7);
    justify-content: center;
    align-items: center;
    gap: 16;
}

.number:hover {
    background-color: #F2F2FA;
    border-radius: 50%;
    height: 40px;
    width: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F2F2FA;
}

.cell.disabled {
    opacity: 0;
    cursor: default;
}

.cell.selected .number {
    border: 1px solid #4775FF;
    color: #45495F;
    border-radius: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
}

.cell.array .number {
    border: 1px solid #4775FF;
    color: white;
    border-radius: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
}