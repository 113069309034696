.avatar-info-first-row-xs {
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-first-row-sm {
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-first-row-md {
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-first-row-lg {
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-first-row-xl {
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-first-row-2xl {
    font-style: normal;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(50, 50, 82, 1);
}

.avatar-info-second-row-xs {
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    /* line-height: 20px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-info-second-row-sm {
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    /* line-height: 20px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-info-second-row-md {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    /* line-height: 24px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-info-second-row-lg {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    /* line-height: 24px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-info-second-row-xl {
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 24px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-info-second-row-2xl {
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 24px; */
    text-align: left;
    color: rgba(131, 131, 173, 1);
}

.avatar-profile-placeholder {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    width: 160px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(50, 50, 82, 1);
}

.avatar-profile-info {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(102, 112, 133, 1);
}

.avatar-group-label-xs {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: rgba(63, 72, 243, 1);
}

.avatar-group-label-sm {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: rgba(63, 72, 243, 1);
}

.avatar-group-label-md {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: rgba(63, 72, 243, 1);
}